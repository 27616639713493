@import "../styles/style.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
$gold: #f2b753;
$goldGradient: #e59e3e, #fdda6f, #e6a140, #e59e3e;
$containerMW: 2800px;

@font-face {
  font-family: "Regolapro";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProBook.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProBook.woff")
      format("woff");
  font-weight: 300;
  /* Lighter */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Regolapro";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProRegular.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProRegular.woff")
      format("woff");
  font-weight: 400;
  /* Normal */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Regolapro";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProMedium.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProMedium.woff")
      format("woff");
  font-weight: 500;
  /* Medium */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Regolapro";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProBold.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/Regola/RegolaProBold.woff")
      format("woff");
  font-weight: 700;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Merriweather";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherLight.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherLight.woff")
      format("woff");
  font-weight: 300;
  /* Normal */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Merriweather";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherRegular.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherRegular.woff")
      format("woff");
  font-weight: 400;
  /* Medium */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Merriweather";
  src: url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherBold.woff2")
      format("woff2"),
    url("https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/fonts/MerriweatherBold.woff")
      format("woff");
  font-weight: 900;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Light.woff2")
      format("woff2"),
    url("/fonts/Poppins-Light.woff")
      format("woff");
  font-weight: 300;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Regular.woff2")
      format("woff2"),
    url("/fonts/Poppins-Regular.woff")
      format("woff");
  font-weight: 400;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Medium.woff2")
      format("woff2"),
    url("/fonts/Poppins-Medium.woff")
      format("woff");
  font-weight: 500;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-SemiBold.woff2")
      format("woff2"),
    url("/fonts/Poppins-SemiBold.woff")
      format("woff");
  font-weight: 600;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Bold.woff2")
      format("woff2"),
    url("/fonts/Poppins-Bold.woff")
      format("woff");
  font-weight: 700;
  /* Bold */
  font-style: normal;
  // font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //font-family: "Poppins", "Montserrat", sans-serif !important;
  // scroll-behavior: smooth !important;
}

body {
  //font-family: "Inter", sans-serif !important;
  font-family: "Regolapro", sans-serif !important;
  font-weight: 400 !important;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  height: 100%;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// span,
// ul,
// li,
// a,
// div,
// header,
// footer,
// main,
// body {
//   font-family: "Inter", sans-serif;;
//   line-height: inherit;
// }
.siqcw-header-detail .left-arrow-icon {
  display: none !important;
}

iframe {
  width: 100%;
}

img {
  font-size: 0px;
  line-height: 0px;
  display: block;
}

b,
strong {
  font-weight: 600;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.merriweather-light {
  font-family: "Merriweather", serif !important;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif !important;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif !important;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif !important;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif !important;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif !important;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif !important;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif !important;
  font-weight: 900;
  font-style: italic;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xxl {
    max-width: 1256px !important;
  }

  .container-xl {
    max-width: 1440px !important;
  }
}

// @media (max-width: 1024px) {
//   .container {
//     max-width: 996px;
//     margin-left: auto;
//     margin-right: auto;
//     padding-left: 28px;
//     padding-right: 28px;
//     font-family: Regolapro, sans-serif;
//     display: block;
//   }
// }

@media (min-width: 820px) and (max-width: 1440px) {
  .container {
    max-width: 996px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    font-family: Regolapro, sans-serif;
    display: block;
  }

  .container-xl {
    max-width: 1200px;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (max-width: 1400px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

:root {
  --background-color-light: #ffffff;
  --text-color-light: #1d1e22;
  --background-color-dark: #000;
  --text-color-dark: #ffffff;
  --border-color-light: #d8d8d8;
  --border-color-dark: #414141;
  --backgoundbtn-light: #000;
  --backgoundbtn-dark: #f00038;
}

body.light {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --border-color: var(--border-color-light);
  --backgoundbtn: var(--backgoundbtn-light);
}

body.dark {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --border-color: var(--border-color-dark);
  --backgoundbtn: var(--backgoundbtn-dark);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  //transition: background-color 0.3s, color 0.3s;
}

.theme-demo {
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--text-color);
  /* Use text color for border */
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Default styles (light theme) */
body.light .lights {
  background-color: #f6f6f6 !important;
  /* Light background */
}

/* Dark mode styles */
body.dark .lights {
  background-color: #333 !important;
  /* Dark background */
}

body.dark .rfm-marquee-container {
  filter: invert(1) brightness(10);
}

body.dark .certificate {
  filter: invert(0) brightness(1);
}

body.light .blog-style p {
  color: #1d1e22;
}

body.dark .blog-style p {
  color: #fff;
}

body.dark .tocitem {
  color: #979797 !important;
}

body.dark .tocitem.active {
  color: #F00036 !important
}

body.dark .drk-shawdow {
  filter: invert(1);
}

.modal-content {
  background-color: var(--background-color);
  border-color: var(--text-color);
}

body.light .closebtn {
  background: #e8e8ed;
}

body.dark .closebtn {
  background-color: #333 !important;
}

body.light .text-color {
  color: #fff;
}

body.dark .text-color {
  color: #000;
}

body.light .Gradient {
  background: rgb(217, 217, 217);
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 1) 0%,
    rgba(115, 115, 115, 1) 37%,
    rgba(0, 0, 0, 1) 73%
  );
}

body.dark .Gradient {
  background: rgb(217, 217, 217);
  background: linear-gradient(
    90deg,
    rgb(71 69 69) 0%,
    rgb(115, 115, 115) 37%,
    rgb(255 248 248) 73%
  );
}

body.light .web-view-header {
  border-bottom: 0.5px solid #dedede;
}

body.dark .web-view-header {
  border-bottom: 0.5px solid #585858;
}

body.light .border-green {
  color: #069d1e;
}

body.dark .border-green {
  color: #00ff2a;
  opacity: 0.5;
}

body.light .border-blue {
  color: #116df7;
}

body.dark .border-blue {
  color: #0062ff;
  opacity: 0.5;
}

body.light .border-orange {
  color: #ff9d28;
}

body.dark .border-orange {
  color: #fe9400;
  opacity: 0.5;
}

body.light .collaboration ul li {
  border-bottom: 1px solid #dedede;
}

body.dark .collaboration ul li {
  border-bottom: 1px solid #585858;
}

body.light .mobile-view-header {
  border-bottom: 0.5px solid #dedede;
}

body.light .active.mobile-view-header {
  border-bottom: 0px solid #dedede;
}

body.dark .mobile-view-header {
  border-bottom: 0.5px solid #585858;
}

body.dark .wht-img {
  filter: invert(1) brightness(1000);
}

body.light .wht-img {
  filter: invert(1) brightness(1000);
}

body.light .white-img {
  filter: invert(0) brightness(0);
}

body.dark .white-img {
  filter: invert(0) brightness(1);
}

body.dark .dark-imgHtl {
  filter: invert(1) brightness(1000);
}

body.dark .dark-txt {
  filter: invert(1) brightness(2);
}

body.dark .dark-img {
  filter: invert(1) brightness(2);
}

body.dark .dark-img-fill {
  fill: white;
}

body.dark .dark-img-footer {
  filter: invert(1) brightness(100);
}

body.dark .EngagementModelsHeaderSvg svg {
  filter: hue-rotate(25deg) contrast(0.7);
}

body.light .header-btn {
  background: #0b0e1e;
  color: #fff;
  border: none;
}

body.dark .header-btn {
  background: #f00038;
  color: #fff;
  border: none;
}

body.light {
  .View-btn {
    color: #000000;

    &:hover {
      color: #ff0080;
    }
  }
}

body.dark {
  .View-btn {
    color: #ffffff;

    &:hover {
      color: #ff0080;
    }
  }
}

body.light {
  .View-btnTwo {
    color: #fff;

    &:hover {
      color: #ff0080;
    }
  }
}

body.dark {
  .View-btnTwo {
    color: #ffff;

    &:hover {
      color: #ff0080;
    }
  }
}

body.light .slick-next:before {
  filter: invert(0);
}

body.dark .slick-next:before {
  filter: invert(1);
}

body.light .slick-prev:before {
  filter: invert(0);
}

body.dark .slick-prev:before {
  filter: invert(1);
}

body.light .pink-btn {
  background: #d25494;
  color: #fff;
}

body.dark .pink-btn {
  background: #f00038 !important;
  color: #fff;
}

body.light .red-app-btn {
  background: #ff0080;
  color: #fff;

  &:hover {
    background: #ff0080de;
  }
}

body.dark .red-app-btn {
  background: #f00038 !important;
  color: #fff;

  &:hover {
    background: #f00038d9 !important;
  }
}

body.light .orange-app-btn {
  background: #ff0080;
  color: #fff;
  border: 0px;
}

body.dark .orange-app-btn {
  background: #f00038 !important;
  color: #fff;
  border: 0px;
}

body.light .proDetail-Heading {
  color: #000;
}

body.dark .proDetail-Heading {
  color: #ffffff;
}

body.light .Today-Box {
  &:hover {
    border-color: #000;
  }
}

body.dark .Today-Box {
  &:hover {
    border-color: #fff;
  }
}

body.light .proDetail-Content {
  color: #9a9a9a;
}

body.dark .proDetail-Content {
  color: #fafafae6;
}

body.light .white-app-btn {
  background: #ffffff;
  color: #000;
}

body.dark .white-app-btn {
  background: #ffffff;
  color: #000;
}

body.light .black-app-btn {
  background: #000;
  color: #ffffff;
}

body.dark .black-app-btn {
  background: #ffffff;
  color: #000;
}

body.light .perfect-match-card {
  background: #f7f7f7;
}

body.dark .perfect-match-card {
  background: #1d1e22;
}

body.light .stack-list::before {
  background: #d8d8d8;
}

body.dark .stack-list::before {
  background-color: #414141;
}

body.light .stack-list li::before {
  background: #d8d8d8;
}

body.dark .stack-list li::before {
  background-color: #414141;
}

body.light .Mobile-App-Counter-Card {
  background: #ffffff;
}

body.dark .Mobile-App-Counter-Card {
  background: #1d1e22;
}

body.light .Mobile-App-Custome-Faq {
  background: #ffffff;
}

body.dark .Mobile-App-Custome-Faq {
  background: #1d1e22;
}

body.light .Mobile-App-Bg-Color {
  background: #f6f4f2 !important;
}

body.dark .Mobile-App-Bg-Color {
  background: #000 !important;
}

body.light .case-counter-card {
  background: #ffd0e8;
}

body.dark .case-counter-card {
  background: #ffd0e8;
}

body.dark .video-bg {
  background: transparent !important;
  color: #fff;
}

body.light .video-bg {
  background: #fff !important;
  color: var(--text-color-light);
}

body.dark .RFP-form {
  border-radius: 15px;
  background: #1d1e22;
}

body.light .RFP-form {
  background: #f9f9f9;
  border-radius: 15px;
}

body.light .testimonial-light {
  background: #f6f6f6;
  color: var(--text-color-light);
}

body.light .case-study-light {
  background: #fff;
  color: var(--text-color-light);
}

body.dark .case-study-light {
  background: #1d1e22;
  color: var(--text-color);
}

body.light .testimonial-dark {
  background: #1d1e22;
  color: var(--text-color-dark);
}

body.dark .testimonial-dark {
  background: #1d1e22;
  color: var(--text-color-dark);
}

body.dark .testimonial-light {
  background: #1d1e22;
  color: var(--text-color);
}

body.light .table-responsive {
  background: #fff;
  color: #000;
}

body.dark .table-responsive {
  background: #1d1e22;
  color: #ffffff;

  border-radius: 16px;
}

body.light .sticky-dark {
  background: #000;
  color: var(--text-color-dark);
}

body.dark .sticky-dark {
  background: #1d1e22;
  color: var(--text-color);
}

body.dark .dark-btn {
  background: #f00038 !important;
  color: #fff !important;
  border: none;
}

body.dark .hone-We-are-text {
  color: #c7c7c7;
}

body.light .dark-btn {
  background: #0b0e1e;
  color: #fff;
  border: none;
}

body.dark .erenberg {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .erenberg {
  background: #f6f4f2 !important;
  color: #000000 !important;
}

body.light .team-dark {
  background: #f6f6f6;
}

body.dark .team-dark {
  background: #1d1e22;
}

body.light .dark-b {
  border-color: #323030;
}

body.dark .dark-b {
  border-color: #f6f6f6;
}

body.light .web-app-dev {
  background: #ecf2f9;
}

body.dark .web-app-dev {
  background: #1d1e22;
}

body.light .box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.dark .box-shadow {
  box-shadow: rgb(255 255 255 / 25%) 0px 2px 8px 0px;
}

body.light .TabInnerDiv:hover::after {
  content: "";
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
}

body.dark .TabInnerDiv:hover::after {
  content: "";
  box-shadow: rgb(255 255 255 / 14%) 0px 0px 8px 8px;
  border-radius: 12px;
}

body.light .front-end-dev {
  background: #e4e4e4;
}

body.dark .front-end-dev {
  background: #1d1e22;
}

body.light .how-we-work-dev {
  background: #f6f6f6;
}

body.dark .how-we-work-dev {
  background: hsl(0, 0%, 0%);
}

body.light .Grey .aceIt-cards-dev {
  background: #e4e4e4;
}

body.dark .Grey .aceIt-cards-dev {
  background: #1d1e22;
  color: #fff !important;
}

body.light .Black .aceIt-cards-dev {
  background: #000 !important;
  color: #fff !important;
}

body.dark .Black .aceIt-cards-dev {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .Team-powered {
  background: #000;
}

body.dark .Team-powered {
  background: #1d1e22;
  border: 1px solid #dee2e6;
}

body.light .services-offer-cards-one {
  background: #f6f6f6;
  color: #000;
  border: 1px solid transparent;
}

body.dark .services-offer-cards-one {
  background: transparent;
  color: #fff;
  border: 1px solid var(--border-color);
}

body.light .services-offer-cards-two {
  background: #000;
  color: #fff;
  border: 1px solid transparent;

  .text-hover {
    padding-bottom: 5px;
    text-decoration: none;
    background: linear-gradient(
        to right,
        rgb(255, 255, 255),
        rgb(255, 255, 255)
      ),
      linear-gradient(
        to right,
        rgba(240, 0, 56, 1),
        rgba(255, 0, 180, 1),
        rgba(255, 0, 128, 1)
      );
    background-size: 100% 0.07em, 0 0.07em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
    width: max-content;
  }

  .text-hover:hover,
  .text-hover:focus {
    background-size: 0 0.07em, 100% 0.07em;
  }
}

body.dark .services-offer-cards-two {
  background: #1d1e22;
  border: 1px solid var(--border-color);
}

body.light .services-offer-cards-four {
  background: #e4e4e4;
  color: #000;
  border: 1px solid transparent;
}

body.dark .services-offer-cards-four {
  background: #1d1e22;
  color: #fff;
  border: 1px solid var(--border-color);
}

body.light .services-offer-cards-three {
  background: #f6f6f6;
}

body.dark .services-offer-cards-three {
  background: transparent;
  color: #fff;
  border: 1px solid var(--border-color);
}

body.light .turn-development-two {
  background: #f6f6f6;
}

body.dark .turn-development-two {
  background: #1d1e22;
  color: #fff;
}

body.light .show-more-button {
  background: #000000;
  color: #fff;
}

body.dark .show-more-button {
  background: #f00038;
  color: #fff;
}

body.light .struggling-cards-big {
  background: #f6f6f6;
}

body.dark .struggling-cards-big {
  background: transparent;
  color: #fff;
  border: 1px solid var(--border-color);
}

body.light .border {
  border: 1px solid #d8d8d8 !important;
}

body.dark .border {
  color: #fff;
  border: 1px solid var(--border-color) !important;
}

// body.light .css-tj5bde-Svg {
//   fill: #d8d8d8;
// }

// body.dark .css-tj5bde-Svg {
//   fill: #414141;
// }

body.light .react-select__indicator-separator {
  background-color: #d8d8d8;
}

body.dark .react-select__indicator-separator {
  background-color: #414141;
}

body.light .BorderTop {
  border-top: 1px solid #d8d8d8 !important;
}

body.dark .BorderTop {
  color: #fff;
  border-top: 1px solid var(--border-color) !important;
}

body.light .Border-Buttom {
  border-bottom: 1px solid #d8d8d8 !important;
}

body.dark .Border-Buttom {
  color: #fff;
  border-bottom: 1px solid var(--border-color) !important;
}

body.light .Border-Left {
  border-left: 1px solid #d8d8d8 !important;
}

body.dark .Border-Left {
  border-left: 1px solid var(--border-color) !important;
}

body.light .Lets-Talk-country {
  .react-select__control {
    border-bottom: 1px solid #d8d8d8 !important;
  }
}

body.dark .Lets-Talk-country {
  .react-select__control {
    border-bottom: 1px solid var(--border-color) !important;
  }
}

body.light .Contact-Us-Form-country {
  .react-select__control {
    border: 1px solid #d8d8d8 !important;
  }
}

body.dark .Contact-Us-Form-country {
  .react-select__control {
    border: 1px solid var(--border-color) !important;
  }
}

body.light .BorderButtom {
  border-bottom: 3px solid #d8d8d8 !important;
}

body.dark .BorderButtom {
  color: #fff;
  border-bottom: 3px solid var(--border-color) !important;
}

body.light .Uploads-File-Bold {
  color: #000000;
}

body.dark .Uploads-File-Bold {
  color: #878787;
}

body.light .Uploads-File-Text {
  color: #4c4c4c;
}

body.dark .Uploads-File-Text {
  color: #656565;
}

body.light .SkillTags {
  span {
    background-color: #1d1e22;
  }
}

body.dark .SkillTags {
  span {
    background-color: #f00038;
  }
}

body.light .hiring-process {
  background: #fff;
  border: 1px solid #d8d8d8 !important;
}

body.dark .hiring-process {
  background: #000000;
  color: #fff;
}

body.light .how-does-table {
  background: #fff;
}

body.dark .how-does-table {
  background: transparent !important;
  color: #fff !important;
}

body.light .how-does-table-icon {
  color: #000000 !important;
}

body.dark .how-does-table-icon {
  fill: #f00038;

  path {
    stroke: #f00038;
  }
}

body.light .how-we-add-table {
  background: transparent;
}

body.dark .how-we-add-table {
  background: transparent !important;
  color: #fff !important;
  border-color: var(--border-color);
}

body.light .how-we-add-table-icon {
  color: #000000 !important;
}

body.dark .how-we-add-table-icon {
  fill: #f00038;

  path {
    stroke: #f00038;
  }
}

.arrowStyle {
  // list-style-image: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg");
  margin-left: 20px;
}

.how-we-add-table {
  th {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 2px;
    padding: 20px 0;
  }

  tr {
    td {
      border-bottom: 1px solid #e5e7eb;
      vertical-align: top;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0px;
    }
  }

  ul {
    li {
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
}

body.light .We-ve-designed-last {
  background: #f6f6f6;
}

body.dark .We-ve-designed-last {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .grey {
  background: #ebebeb;
}

body.dark .grey {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .pink {
  background: #ffcaf0;
}

body.dark .pink {
  color: #000 !important;
}

body.light .yellow {
  background: #ccf095 !important;
}

body.dark .yellow {
  background: #ccf095 !important;
  color: #000 !important;
}

body.light .MySquardRightBottomCar {
  background: #f6f6f6;
  color: #000000 !important;
}

body.dark .MySquardRightBottomCar {
  background: #000000 !important;
  color: #fff !important;
}

body.light .ShapeCards-Main {
  background: #f6f6f6;
  color: #000000 !important;
}

body.dark .ShapeCards-Main {
  background: #1d1e22 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .GlobalQuotes {
  background: #000;
}

body.dark .GlobalQuotes {
  background: #1d1e22;
}

body.light .our-services-card {
  background: #f6f6f6;
}

body.dark .our-services-card {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .our-services-card-two {
  background: #ffcaf0;
}

body.dark .our-services-card-two {
  background: #ffcaf0 !important;
  color: #000 !important;
}

body.light .how-we-add-card {
  background: #fff;
}

body.dark .how-we-add-card {
  background: #000000 !important;
  color: #fff !important;
}

body.light .we-have-knack-card-one {
  background: #f6f6f6 !important;
}

body.dark .we-have-knack-card-one {
  background: #2a2a2d !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .we-have-knack-card-two {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

body.dark .we-have-knack-card-two {
  background: #1d1e22 !important;
  border: 1px solid var(--border-color);
}

body.light .we-have-knack-card-three {
  background: #fcc5e8 !important;
}

body.dark .we-have-knack-card-three {
  background: #fcc5e8 !important;
  color: #000;
}

body.light .we-have-knack-card-four {
  background: #f6f6f6 !important;
  border: 1px solid transparent;
}

body.dark .we-have-knack-card-four {
  background: #000000 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .we-have-knack-card-five {
  background: #f6f6f6 !important;
  border: 1px solid transparent;
}

body.dark .we-have-knack-card-five {
  background: #000000 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .we-have-knack-card-six {
  background: #1d1e22 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.dark .we-have-knack-card-six {
  background: #1d1e22 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .benefits-card-one {
  background: #f6f6f6;
  color: #000 !important;
}

body.dark .benefits-card-one {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .benefits-card-two {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid #000;
}

body.dark .benefits-card-two {
  background: #1d1e22 !important;
  border: 1px solid var(--border-color);
}

body.light .benefits-card-three {
  background: #f6f6f6;
  color: #000 !important;
}

body.dark .benefits-card-three {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid var(--border-color);
}

body.light .ourReactJs-card-two {
  background: #000 !important;
  color: #fff !important;
}

body.dark .ourReactJs-card-two {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .ourReactJs-card-five {
  background: #000 !important;
  color: #fff !important;
}

body.dark .ourReactJs-card-five {
  background: #1d1e22 !important;
  color: #fff !important;
}

body.light .discover-cars-one {
  background: #f6f6f6;
  color: #000;
}

body.dark .discover-cars-one {
  background: #000;
  color: #fff;
}

body.light .discover-cars-two {
  background: #000;
  color: #fff;
}

body.dark .discover-cars-two {
  background: #1d1e22;
  color: #fff;
}

body.light .discover-cars-three {
  background: #000;
  color: #fff;
}

body.dark .discover-cars-three {
  background: #1d1e22;
  color: #fff;
}

body.light .find-your-team-card-one {
  background: #f6f6f6;
}

body.dark .find-your-team-card-one {
  background: #000;
}

body.lights .from-input {
  background: #f6f6f6;
}

body.dark .from-input {
  filter: invert(1);
}

body.lights .from-option {
  background: #f6f6f6 !important;
}

body.dark .from-option {
  background: #000;
  color: #fff;
}

body.lights .contact-us-slider {
  background: #000;
}

body.dark .contact-us-slider {
  background: #1d1e22;
}

body.lights .New-Story-bg {
  background: #EEEEEE;
}

body.dark .New-Story-bg {
  background: #000000;
}

body.lights .BoxShowdowRight {
  background: linear-gradient(to left, rgb(255, 255, 255) 10%, rgba(255, 255, 255, 0) 90%);
}

body.dark .BoxShowdowRight {
  background: linear-gradient(to left, rgb(0 0 0) 10%, rgba(255, 255, 255, 0) 90%);
}

body.lights .BoxShowdowLeft {
  background: linear-gradient(to right, rgb(255 255 255) 10%, rgba(255, 255, 255, 0) 90%);
}

body.dark .BoxShowdowLeft {
  background: linear-gradient(to right, rgb(0 0 0) 10%, rgba(255, 255, 255, 0) 90%);
}

body.light .zoomview {
  background: #e4e4e4;
}

body.dark .zoomview {
  background: #1d1e22 !important;
  color: #fff;
}

body.light .AIDiscover {
  background: #f6f6f6;
}

body.dark .AIDiscover {
  background: #1d1e22;
  color: #fff;
}

body.light .icon-bg-color {
  background: #000000;
}

body.dark .icon-bg-color {
  background: #ffffff;
 
}

body.light .zoomviewTwo {
  background: #000;
  color: #fff;
}

body.dark .zoomviewTwo {
  background: #1d1e22;
  color: #fff;
}

body.light .WhatWeDo-card-one {
  background: #f6f6f6;
  color: #000;
}

body.dark .WhatWeDo-card-one {
  background: #000000;
  color: #fff;
}

body.light .WhatWeDo-card-two {
  background: #000000;
  color: #fff;
}

body.dark .WhatWeDo-card-two {
  background: #1d1e22;
  color: #fff;
}

body.light .WhatWeDo-card-three {
  background: #c6f4fe;
  color: #000;
}

body.dark .WhatWeDo-card-three {
  background: #c6f4fe;
  color: #000;
}

body.light .WhatWeDo-card-four {
  background: #f6f6f6;
  color: #000;
}

body.dark .WhatWeDo-card-four {
  background: #000000;
  color: #fff;
}

body.light .folder {
  fill: #f6f6f6;
}

body.dark .folder {
  fill: #1d1e22;
}

body.light .WhatWeDo-card-five {
  background: #f6f6f6;
  color: #000;
}

body.dark .WhatWeDo-card-five {
  background: #000000;
  color: #fff;
}

body.light .WhatWeDo-card-six {
  background: #f6f6f6;
  color: #000;
}

body.dark .WhatWeDo-card-six {
  background: #000000;
  color: #fff;
}

body.light .border-bottom {
  border-bottom: 1px solid #000000 !important;
}

body.dark .border-bottom {
  border-bottom: 1px solid #fff !important;
}

body.light .BackEnd-card-one {
  background: #ccf095;
  color: #000;
}

body.dark .BackEnd-card-one {
  background: #ccf095;
  color: #000;
}

body.light .AccessibleCard {
  background: #f8f8f8;
  color: #000;
}

body.dark .AccessibleCard {
  background: #1d1e22;
  color: #fff;
}

body.light .BackEnd-card-two {
  background: #f6f6f6;
  color: #000;
}

body.dark .BackEnd-card-two {
  background: #1d1e22;
  color: #fff;
  border: 1px solid #ffffff5c;
}

body.light .case-study-dark-mod {
  background: #f6f4f2;
  color: #000;
}

body.dark .case-study-dark-mod {
  background: #1d1e22;
  color: #fff;
}

body.light .BackEnd-card-three {
  background: #e4e4e4;
  color: #000;
}

body.dark .BackEnd-card-three {
  background: #e4e4e4;
  color: #000;
}

body.light .BackEnd-card-four {
  background: #def0f3;
  color: #000;
}

body.dark .BackEnd-card-four {
  background: #def0f3;
  color: #000;
}

body.light .Here-are-card-one {
  background: #f6f6f6;
  color: #000;
}

body.dark .Here-are-card-one {
  background: #000;
  color: #fff;
}

body.light .light-pink {
  background: #fdf3f3;
  color: #000;
}

body.dark .light-pink {
  background: #000;
  color: #fff;
}

body.light .light-white {
  background: #ffffff;
}

body.dark .light-white {
  background: #000;
}

body.light .Here-are-card-two {
  background: #000000;
  color: #ffffff;
}

body.dark .Here-are-card-two {
  background: #1d1e22;
  color: #fff;
}

body.light .SocialMediaSecondSecLeft {
  background: #000000;
  color: #ffffff;
}

body.dark .SocialMediaSecondSecLeft {
  background: #1d1e22;
  color: #fff;
}

body.light .CrossPlatFromHowWeDoCard {
  background: #f6f6f6;
  color: #000;
}

body.dark .CrossPlatFromHowWeDoCard {
  background: #1d1e22;
  color: #fff;
}

body.light .lets-talk-Main {
  background: #f6f6f6;
  color: #000;
}

body.dark .lets-talk-Main {
  color: #fff;
}

body.light .re-auth {
  color: #909090;
}

body.dark .re-auth {
  color: #fff;
}

body.light .case-bg {
  background: #f7f7f7;
}

body.dark .case-bg {
  background: #1d1e22;
}

body.light .Careers-Slug-Active {
  background: #000;
  border: 1px solid var(--border-color) !important;
}

body.dark .Careers-Slug-Active {
  background: #f2f2f2;
  border: 1px solid var(--border-color) !important;
  color: #000;
}

body.light .Careers-Slug-InActive {
  background: #f2f2f2;
  border: 1px solid var(--border-color) !important;
}

body.dark .Careers-Slug-InActive {
  background: #000;
  color: #fff;
  border: 1px solid var(--border-color) !important;
}

body.light .stylebase-nav {
  background: #f7f7f7;
}

body.dark .stylebase-nav {
  background: #1d1e22;
}

body.light .phone-code {
  color: #000 !important;

  :hover {
    color: #000 !important;
  }
}

body.dark .phone-code {
  color: #fff !important;

  :hover {
    color: #fff !important;
  }
}

body.light .case-bg-img {
  background: #f0f0f0;
}

body.dark .case-bg-img {
  background: #000;
}

body.light .cardBorder {
  border: 1px solid #d9d9d9;
  background: #f6f6f6;
  color: #000;
}

body.dark .cardBorder {
  background: #1d1e22;
  border: 1px solid var(--border-color) !important;
  color: #ffffff;
}

body.light .relatedexpertise {
  border: none !important;
  background: #f6f6f6;
  color: #000;
}

body.dark .relatedexpertise {
  background: #1d1e22;
  border: none;
  color: #ffffff;
}

body.light .LightDarkBg {
  background: #f6f4f2;
  color: #000;
}

body.dark .LightDarkBg {
  background: #000000;
  color: #ffffff;
}

body.light .LightDarksubCat {
  color: #6a6868;
}

body.dark .LightDarksubCat {
  color: #ffffffd6;
}

body.light .hook-input {
  color: #6a6868 !important;
}

body.dark .hook-input {
  color: #ffffffcc !important;
}

body.light .react-select__menu {
  background: #fff;
}

body.dark .react-select__menu {
  background: #1d1e22;
}

body.light .LetsTalkFormMain {
  background: #fff;
}

body.dark .LetsTalkFormMain {
  background: #1d1e22;
}

body.light .BlogRecentImg {
  background: #f6f4f2;
}

body.dark .BlogRecentImg {
  background: #1d1e22;
}

body.light .CaseSliderCardMain {
  background: #f5f5f5;
  color: #000;
}

body.dark .CaseSliderCardMain {
  background: #1d1e22;
  color: #fff;
}

body.light .CatoBtn {
  color: #1d1e2273;
}

body.dark .CatoBtn {
  color: #fff;
}

body.light .CaseDesc {
  color: #7b7b7b;
}

body.dark .CaseDesc {
  color: #fff;
}

body.light .SingleCaseTitle {
  color: #8e8e90;
}

body.dark .SingleCaseTitle {
  color: #fafafae6;
}

body.light .CaseImg1Dark {
  background: #e4e4e4;
}

body.dark .CaseImg1Dark {
  background: #1d1e22;
}

body.light .contact-form {
  background: #f7f7f7;
}

body.dark .contact-form {
  background: #1d1e22;
}

body.light .ContactInput {
  border: 1px solid #d8d8d8;
}

body.dark .ContactInput {
  border: 1px solid #414141;
}

body.light .CaseImg2Dark {
  background: #ffffff;
}

body.dark .CaseImg2Dark {
  background: #1d1e22;
}

body.light .formselect {
  background: #fff;
}

body.dark .formselect {
  background: #000000;
}

body.light .ArianeBgColor {
  background: #e4e4e4;
}

body.dark .ArianeBgColor {
  background: #2f2f2f;
}

body.light .NewStoryIndustryBG {
  background: #d4dae1;
}

body.dark .NewStoryIndustryBG {
  background: #2f2f2f;
}

body.light .folderTwo {
  fill: #e4e4e4;
}

body.dark .folderTwo {
  fill: #2f2f2f;
}

body.light .check-box {
  filter: invert(0);
}

body.dark .check-box {
  filter: invert(1);
}

body.light .lets-talks-form {
  background: #fff;
}

body.dark .lets-talks-form {
  background: #000;
}

body.light .react-select__single-value {
  color: #6a6868 !important;
}

body.dark .react-select__single-value {
  color: #ffffffcc !important;
}

body.light .case-study-review {
  background: #fff;
}

body.dark .case-study-review {
  background: #000;
}

body.light .react-select__menu {
  div {
    &:hover {
      color: #000000;
    }
  }
}

body.dark .react-select__menu {
  div {
    color: #fff;

    &:hover {
      color: #000000;
    }
  }
}

body.dark .ChallengesCard {
  background: #2a2a2d;
  color: #ffffff;
}

body.dark .LogoTransform {
  background: #2a2a2d;
}

body.dark :hover {
  &.ProducDevelopment {
    outline: 10px solid rgb(181 181 181 / 50%);
    border-radius: 10px;
    background-color: rgb(79 79 79 / 71%) !important;
    border: 0.5px solid #5d5d5d !important;
  }
}

.hs-button {
  width: 100%;
}

body.light .header-icon {
  filter: invert(0);
}

body.dark .header-icon {
  filter: invert(1);
}

body.light .header-icon.active {
  filter: invert(0);
}

body.dark .header-icon.active {
  filter: invert(0);
}

.mobile-slider .slick-track {
  display: flex;
  gap: 1rem;
}

.HUderLine {
  text-decoration: underline;
  display: inline;
  padding: 5px 4px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  width: max-content;

  &:hover {
    color: #fff;
    background: #f4004c;
  }
}

.submenubar {
  z-index: 1;
  box-sizing: border-box;
  border-bottom: 3px solid #cccccc00;
  transition: all 0.5s ease;

  &::before {
    content: " ";
    height: 55px;
    position: absolute;
    top: 0;
    display: block;
    z-index: 0;
  }

  &::after {
    padding: 50px;
    display: block;
  }

  a {
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.rfm-child {
  a {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  img {
    width: auto;
    max-width: 100px;
    height: auto;
  }

  @media (max-width: 600px) {
    img {
      width: 70px;
      margin-left: 2rem;
    }
  }
}

.social-icons {
  .icon {
    font-size: 32px; // Ensure the icon has a visible size
    color: inherit; // Inherit the default text color or set your own color
    transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    padding: 5px;
    border-radius: 50%; // Circular background effect
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    // Default icon color when not hovered
    &[icon*="facebook"] {
      color: #1877f2; // Facebook color
    }

    &[icon*="twitter"] {
      color: #ffffff; // twitter color
    }

    &[icon*="instagram"] {
      color: #e4405f; // Instagram color
    }

    &[icon*="youtube"] {
      color: #ff0000; // YouTube color
    }

    &[icon*="linkedin"] {
      color: #0a66c2; // LinkedIn color
    }

    &[icon*="behance"] {
      color: #0057ff; // Behance color
    }

    &[icon*="dribbble"] {
      color: #ea4c89; // Dribbble color
    }

    &:hover {
      transform: translateY(0px) scale(1.05); // Move up and increase size
      color: white; // Change icon color to white
      opacity: 1;
    }
  }

  // Facebook hover background color
  .facebook-icon:hover {
    background-color: #1877f2; // Facebook blue background
  }

  // Facebook hover background color
  .twitter-icon:hover {
    background-color: #000; // Facebook blue background
  }

  // Instagram hover background color
  .instagram-icon:hover {
    background-color: #e4405f; // Instagram pink-red background
  }

  // YouTube hover background color
  .youtube-icon:hover {
    background-color: #ff0000; // YouTube red background
  }

  // LinkedIn hover background color
  .linkedin-icon:hover {
    background-color: #0a66c2; // LinkedIn blue background
  }

  // Behance hover background color
  .behance-icon:hover {
    background-color: #0057ff; // Behance blue background
  }

  // Dribbble hover background color
  .dribbble-icon:hover {
    background-color: #ea4c89; // Dribbble pink background
  }
}

.privacy {
  h1 {
    margin-bottom: 56px;
    font-size: 84px;
    font-weight: 500;
    line-height: 92px;
  }

  h2 {
    font-size: 23px;
    line-height: 40px;
    letter-spacing: -1.5px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 700;
  }

  p {
    letter-spacing: -0.01em;
    margin-bottom: 36px;
    font-size: 20px;
    line-height: 32px;
  }

  a {
    cursor: pointer;
    color: #f00038;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    margin-bottom: 36px;
    list-style-type: disc;
    margin-top: 0;
    padding-left: 40px;

    li {
      list-style: disc;
      padding: 5px 0;

      ul {
        margin-top: 10px;
      }
    }

    ul {
      margin-left: 30px;
    }
  }

  @media screen and (min-width: 1440px) {
    h1 {
      font-size: 96px;
      line-height: 106px;
    }

    h2 {
      font-size: 58px;
      line-height: 76px;
    }

    h3 {
      font-size: 36px;
      line-height: 48px;
    }

    p {
      font-size: 26px;
      line-height: 42px;
    }

    li {
      font-size: 26px;
      line-height: 42px;
    }
  }

  @media screen and (max-width: 991px) {
    h1 {
      margin-bottom: 42px;
      font-size: 62px;
      line-height: 70px;
    }

    h2 {
      font-size: 34px;
      line-height: 46px;
    }

    h3 {
      font-size: 22px;
      line-height: 32px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }

    ul {
      padding-left: 30px;
    }

    li {
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media screen and (max-width: 479px) {
    h1 {
      margin-bottom: 20px;
      font-size: 34px;
      line-height: 40px;
    }

    h2 {
      margin-bottom: 32px;
      font-size: 26px;
      line-height: 36px;
    }

    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 26px;
    }

    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 20px;
    }

    li {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .ALink {
    &:hover {
      color: #fff;
      background: #f4004c;
    }
  }
}

.GIntInvestorsSection {
  .zoomview {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      padding: 2.5rem;
    }
  }
}

.flip-horizontal {
  transform: scaleX(-1);

  img {
    transform: scaleX(-1);
    position: relative;
  }

  .htitle {
    transform: scaleX(-1);
  }
}

.fullsizesvg {
  svg {
    width: 100%;
  }
}

.fullsizesvgHauto {
  svg {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

body.light .text-hover {
  padding-bottom: 5px;
  text-decoration: none;
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 0)),
    linear-gradient(
      to right,
      rgba(240, 0, 56, 1),
      rgba(255, 0, 180, 1),
      rgba(255, 0, 128, 1)
    );
  background-size: 100% 0.07em, 0 0.07em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  width: max-content;
}

body.dark .text-hover {
  padding-bottom: 5px;
  text-decoration: none;
  background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)),
    linear-gradient(
      to right,
      rgba(240, 0, 56, 1),
      rgba(255, 0, 180, 1),
      rgba(255, 0, 128, 1)
    );
  background-size: 100% 0.07em, 0 0.07em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  width: max-content;
}

body.dark .text-hover:hover,
.text-hover:focus {
  background-size: 0 0.07em, 100% 0.07em;
}

body.light .text-hover:hover,
.text-hover:focus {
  background-size: 0 0.07em, 100% 0.07em;
}

.isty {
  position: absolute;
  margin: auto !important;
  bottom: 0px;
  top: -15px;
  right: -15px;
  left: -15px;

  @media (max-width: 479px) {
    top: -10px;
    right: -7px;
  }
}

.scroll-container {
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    font-size: 10px;
  }

  @media screen and (max-width: 667px) {
    display: none;
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    justify-content: space-between;
    height: 370px;
    padding-bottom: 0;
  }

  svg {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.scroll-section {
  text-align: center;
  background-color: #fbfbfb;
  border-radius: 8px;
  flex: auto;
  padding: 16px 20px;
  display: inline-block;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 11px;
  }

  @media screen and (max-width: 479px) {
    border-radius: 7px;
    flex: 0 auto;
    justify-content: flex-start;
    align-self: center;
    padding: 12px 16px;
    position: relative;
  }
}

.startbuild {
  gap: 3rem;

  svg {
    width: 70%;
  }

  &.HomeTwoTechnologies {
    gap: 6rem;

    svg {
      filter: grayscale(1) brightness(1) opacity(0.6);

      &:hover {
        filter: grayscale(0) brightness(1) opacity(1);
      }
    }
  }

  &.SeoServicesTechnologies {
    gap: 4rem;

    svg {
      filter: grayscale(1) brightness(1) opacity(0.6);

      &:hover {
        filter: grayscale(0) brightness(1) opacity(1);
      }
    }
  }

  @media (min-width: 1440px) {
    gap: 6rem;

    svg {
      width: 100%;
    }

    &.HomeTwoTechnologies {
      gap: 12rem;
    }

    &.SeoServicesTechnologies {
      gap: 6rem;
    }
  }

  @media (max-width: 1024px) {
    gap: 0rem;

    svg {
      width: 70%;
    }
  }

  @media (max-width: 820px) {
    &.SeoServicesTechnologies {
      svg {
        min-width: 130px;
      }
    }
  }

  @media (max-width: 778px) {
    gap: 0rem;

    svg {
      width: 60%;
    }
  }

  @media (max-width: 600px) {
    svg {
      width: 100%;
    }

    &.HomeTwoTechnologies {
      gap: 0rem;

      svg {
        height: 44px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 475px) {
  .scrolllogo-first {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    margin-top: -100px;
  }

  .scrolllogo-second {
    display: flex;
    gap: 20px;
    animation: marquee 20s linear infinite;
  }

  .scrolllogo-second a {
    width: max-content;
    display: inline-block;
  }

  @keyframes marquee {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-100%);
    }
  }
}

.scroll-sectionTwo {
  text-align: center;
  border-radius: 8px;
  flex: auto;
  padding: 16px 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  border: 1px solid var(--border-color);

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 11px;
  }

  @media screen and (max-width: 479px) {
    border-radius: 7px;
    flex: 0 auto;
    justify-content: flex-start;
    align-self: center;
    padding: 12px 16px;
    position: relative;
  }
}

.s2 {
  z-index: -1;
  background-color: #d8d8d8;
  margin-left: -10px;
  padding-left: 30px;
  position: relative;

  @media screen and (max-width: 991px) {
    z-index: -1;
    margin-left: -10px;
    padding-left: 20px;
    position: relative;
  }
}

.s3 {
  z-index: -2;
  background-color: #909090;
  margin-left: -10px;
  padding-left: 30px;
  position: relative;
  color: var(--text-color-dark);

  @media screen and (max-width: 991px) {
    padding-left: 25px;
  }
}

.s4 {
  z-index: -3;
  background-color: #555353;
  margin-left: -10px;
  padding-left: 30px;
  position: relative;
  color: var(--text-color-dark);

  @media screen and (max-width: 991px) {
    padding-left: 25px;
  }
}

.s5 {
  z-index: -4;
  background-color: #1d1e22;
  margin-left: -10px;
  padding-left: 30px;
  position: relative;
  color: var(--text-color-dark);

  @media screen and (max-width: 991px) {
    padding-left: 25px;
  }
}

.s6 {
  z-index: -4;
  background-color: #17313b;

  position: relative;
  color: var(--text-color-dark);

  @media screen and (max-width: 991px) {
    padding-left: 25px;
  }
}

//flowchartstart
.stacked-flowchart {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;

  @media (max-width: 667px) {
    display: flex;
  }
}

.stacked-item {
  max-width: 300px;
  text-align: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  color: #fff;
}

.non-technical {
  background-color: #e0e0e0;
  color: #000;
}

.automated {
  background-color: #fbfbfb;
  color: #000;
}

.decision {
  background-color: #d8d8d8;
  color: #000;
}

.algorithms {
  background-color: #909090;
}

.derived {
  background-color: #555353;
}

.raw {
  background-color: #1d1e22;
}

.technical {
  background-color: #000000;
  color: #d3d3d3;
}

@media screen and (max-width: 768px) {
  .stacked-item {
    font-size: 12px;
    padding: 10px;
  }
}

//flowchartend

//footer Start
.fclutch {
  .c-white {
    display: none;
  }

  @media (min-width: 1440px) {
    top: -30px;
  }

  @media (max-width: 1024px) {
    top: -15px;

    .c-white {
      display: none;
    }
  }

  @media (max-width: 992px) {
    left: 165%;
    top: -45px;

    .c-black {
      display: none;
    }

    .c-white {
      display: block;
    }
  }

  @media (max-width: 778px) {
    svg {
      width: 200px;
    }
  }

  @media (max-width: 480px) {
    left: 0%;
    top: -90px;
  }
}

//Footer End

html {
  height: 100%;
}

.modal-backdrop.show {
  backdrop-filter: blur(14px);
  background: #0000007d;
  opacity: 1 !important;
}

.modal-content {
  border-radius: 15px;
  border: 0px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.footer {
  li {
    font-size: 13px;
    line-height: 38px;
    font-weight: 300;
  }
}

.footer-mobile {
  @media (max-width: 778px) {
    background-color: #1d1e22;
    padding: 24px 24px 48px 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
}

//android app
.for-line {
  position: relative;

  &:before {
    content: "";
    width: 40px;
    height: 1px;
    background-color: #909090;
    display: block;
    position: absolute;
    z-index: 9999;
    left: 0px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
}

.tree-list {
  list-style: none;
  padding-left: 0px;
  position: relative;
}

.tree-list::before {
  content: "";
  position: absolute;
  left: 0;
  top: 31px;
  height: calc(100% - 76px);
  width: 1px;
  background-color: #909090;

  @media (max-width: 600px) {
    top: 41px;
    height: calc(100% - 96px);
  }
}

.tree-list li {
  position: relative;
  padding-left: 80px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    padding-left: 50px;
    margin-bottom: 15px;
  }
}

.tree-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 1px;
  background-color: #909090;
}

.stack-list {
  list-style: none;
  padding-left: 0px;
  position: relative;
}

.stack-list::before {
  content: "";
  position: absolute;
  left: 0;
  top: -46px;
  height: calc(100% - 0px);
  width: 1px;
  background-color: #909090;

  @media (max-width: 600px) {
    top: 28px;
    height: calc(100% - 70px);
  }
}

.stack-list li {
  position: relative;
  padding-left: 80px;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    padding-left: 35px;
    margin-bottom: 15px;
  }
}

.stack-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 1px;
  background-color: #909090;

  @media (max-width: 600px) {
    width: 25px;
  }
}

//For builder.ai like slider start

.business-needs-slider-main {
  width: 100%;
  overflow: hidden;

  .business-needs-slider {
    max-width: 1440px;
    margin: 0 auto;
    background-color: #6200ea;
    border-radius: 10px;

    @media (max-width: 1200px) {
      margin-left: 2.5%;
      margin-right: 2.5%;
    }

    @media (max-width: 991px) {
      margin-left: 2.5%;
      margin-right: 2.5%;
    }

    @media (max-width: 475px) {
      max-width: 100%;
    }
  }

  .slider-heading {
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .glide__track {
    overflow: visible;
    height: 507px;
    display: flex;
    align-items: flex-end;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    @media (max-width: 991px) {
      height: 514px;
    }

    @media (max-width: 475px) {
      height: 470px;
    }
  }

  .glide__slides {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .glide__slide {
    padding: 10px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: translateY(0px) rotate(0deg) scaleY(1) scaleX(1);
  }

  .glide__slide--active {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
    transform: translateY(-3rem) rotate(0deg) scaleY(1) scaleX(1);
    opacity: 1;
    position: relative;
  }

  .glide__slide--active .slide-card {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  }

  .slide-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 2.5px solid #000000;
    background-color: white;
    border-radius: 14px;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    min-height: 370px;
    user-select: text;

    @media (max-width: 1200px) {
      min-height: 315px;
    }

    @media (max-width: 1023px) {
      min-height: 315px;
    }
  }

  .slide-card h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #000;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
      font-size: 22px;
      line-height: 30px;
    }

    @media (max-width: 475px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .slide-card p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: #000;
    margin-top: 40px;

    @media (max-width: 1200px) {
      margin-top: 0px;
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 991px) {
      margin-top: 0px;
    }

    @media (max-width: 475px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .slide-card a {
    display: inline-block;
    margin-top: 15px;
    font-weight: bold;
    color: #00c853;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease;
  }

  .slide-card a:hover {
    border-bottom-color: #00c853;
  }

  .glide__arrows {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .glide__arrow {
    border: none;
    padding: 0px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    box-shadow: unset;
    text-shadow: unset;
  }

  .glide__arrow--left {
    left: 0em;
  }

  .glide__arrow--right {
    right: 0em;
  }
}

.digital-marketing-Glide {
  .glide__track {
    overflow: visible;
    height: 450px;
    display: flex;
    align-items: flex-start;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    @media (max-width: 1024px) {
      height: 420px;
    }

    @media (max-width: 991px) {
      height: 390px;
    }

    @media (max-width: 475px) {
      height: 400px;
    }
  }

  .glide__slide {
    padding: 10px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: translateY(0px) rotate(0deg) scaleY(1) scaleX(1);
  }

  .glide__slide--active {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
    transform: translateY(3rem) rotate(0deg) scaleY(1) scaleX(1);
    opacity: 1;
    position: relative;
  }
}

//For builder.ai like slider end

//uiux
.uiux {
  tr {
    height: 72px;
    vertical-align: middle;
  }
}

@media (max-width: 600px) {
  .ui-ux-table {
    width: 700px;
  }
}

//e-commerce development
.t-left-m-center {
  text-align: left !important;

  @media (max-width: 600px) {
    text-align: center !important;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

//StartUp Page
.collaboration {
  ul {
    li {
      border-bottom: 1px solid #ccc;
    }

    li:last-child {
      border-bottom: 0px solid #ccc !important;
      padding-bottom: 0px !important;
    }
  }
}

.collaborationTwo {
  ul {
    li {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.common-btn-hover {
  transition: all 0.5s ease;

  &:hover {
    background: #ff0080 !important;
    color: #fff !important;
    transition: all 0.5s ease;
  }
}

.lets-talks-form {
  background: #ffffff;
  border: 1px solid rgba(216, 216, 216, 0.73);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .check-box {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  .react-select__control {
    flex-wrap: nowrap !important;
    max-height: 61px !important;

    .react-select__indicators {
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      .react-select__indicator-separator {
        height: 20px;
      }
    }
  }

  .react-select__control--is-focused {
    border-bottom: 1px solid #c1c1c1 !important;
  }

  .css-13cymwt-control {
    border-top: unset !important;
    border-right: unset !important;
    border-left: unset !important;
    border-bottom: 1px solid #c1c1c1 !important;
    border-radius: 0px;
    padding: 30px 0px;
    background: transparent;

    .css-19bb58m {
      display: block;

      input,
      select {
        opacity: 0 !important;
        border-bottom: 0px solid #c1c1c1 !important;
        background: transparent;
      }
    }

    .react-select__value-container {
      .react-select__input-container {
        .react-select__input {
          opacity: 0 !important;
          border-bottom: 0px solid #c1c1c1 !important;
          width: 0px !important;
          background: transparent;

          input,
          select {
            opacity: 0 !important;
            border-bottom: 0px solid #c1c1c1 !important;
            background: transparent;
          }
        }
      }
    }

    // .react-select__indicator-separator
    // {
    //   height: 46px;
    //   margin: 0 auto;
    // }
  }

  .css-t3ipsp-control {
    border-bottom: 1px solid #c1c1c1 !important;
    outline: unset !important;
    box-shadow: unset !important;
    min-height: 61px !important;
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0px !important;
    border-style: solid !important;
    border-top-width: 0px !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    background: transparent;
  }

  .css-t3ipsp-control:hover {
    outline: unset !important;
    box-shadow: unset !important;
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 0px !important;
  }

  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: transparent;
    outline: 0;
    box-shadow: unset;
  }

  select {
    color: #c5c5c5 !important;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.2px;
    font-weight: 300;
  }

  .form-select:focus {
    color: #000 !important;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: transparent;
    outline: 0;
    box-shadow: unset;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.2px;
    font-weight: 300;
  }

  .lets-btn {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
    font-weight: 500;
    background: #ff0080;
    border: 1px solid #d6d6d6;
    border-radius: 11px;
  }

  .col-md-12 {
    margin: 15px auto;
  }

  input,
  select {
    height: 60px;
    border-bottom: 1px solid #c1c1c1 !important;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    font-size: 16px;
    padding: 0;
    background: transparent;

    &.check-box {
      width: 32px;
      height: 32px;
      border: 1px solid #d6d6d6;
      margin: 0;
    }
  }

  ::placeholder {
    color: #c5c5c5 !important;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
    font-weight: 300;
  }

  textarea {
    height: 165px;
    border-bottom: 1px solid #c1c1c1 !important;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    font-size: 20px;
    padding: 0;
    margin: 20px auto;
    background: transparent;
  }

  label {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  @media (max-width: 600px) {
    .lets-btn {
      height: 45px;
      font-size: 16px;
      line-height: 26px;
    }

    label {
      font-size: 16px;
      line-height: 26px;
    }

    textarea {
      height: 70px;
    }

    ::placeholder {
      font-size: 16px;
      line-height: 26px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: 1px solid #000000;
    // -webkit-text-fill-color: #f1f1f1;
    // -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

input:-webkit-autofill {
  background-color: transparent !important;
  color: inherit !important;
}

.ContactInput {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: 1px solid #000000;
    // -webkit-text-fill-color: #f1f1f1;
    // -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.react-select__control {
  flex-wrap: nowrap !important;
  max-height: 46px !important;
  background-color: transparent !important;
  box-shadow: unset;
  border-color: #c5c5c5 !important;
}

.react-select__value-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  padding: 0 4px !important;
  height: 60px;
  text-indent: 5px;
}

.react-select__indicator {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.contact-form {
  background: #f7f7f7;
  border-radius: 16px;

  input,
  select {
    height: 46px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    font-size: 15px;
  }

  label {
    font-size: 15px;
  }

  textarea {
    height: 165px;
    font-size: 15px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }

  .contact-btn {
    width: 100%;
    height: 46px;
    border-radius: 8px;
    border: 0;
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    padding: 1.5rem;

    .tab_view {
      padding-right: 0;
    }
  }
}

.videoModalMain {
  .modal-content {
    border-radius: 30px;
    overflow: hidden;
    border: 0;
  }
}

.home-slider {
  .slick-slider {
    margin-left: -7px;
    margin-right: -7px;

    .slick-slide {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  .slick-dots {
    bottom: 56px;

    @media (max-width: 1536px) {
      bottom: 20px;
    }
  }

  .slick-track {
    display: flex;
    gap: 1rem;

    @media (max-width: 430px) {
      gap: 0rem;
    }
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .slick-next {
    right: 0;
    height: 64px;
    width: 56px;
    z-index: 5;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }

    @media (max-width: 768px) {
      height: 50px;
      width: 44px;
    }
  }

  .slick-prev {
    left: 0;
    height: 64px;
    width: 56px;
    z-index: 5;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }

    @media (max-width: 768px) {
      height: 50px;
      width: 44px;
    }
  }

  .slick-dots {
    bottom: -56px;

    li {
      button {
        &:before {
          opacity: 1;
          color: transparent;
          width: 16px;
          height: 16px;
          border: 1px solid $gold;
          background: transparent;
        }
      }

      &.slick-active {
        button {
          &:before {
            opacity: 1;
            color: $gold;
            width: 16px;
            height: 16px;
            background: $gold;
          }
        }
      }
    }
  }
}

.Case-slider {
  @media (max-width: 1024px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  @media (max-width: 778px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  .slick-prev,
  .slick-next {
    bottom: unset;
    top: -15%;
  }

  .slick-prev {
    left: 85%;

    @media (max-width: 1024px) {
      left: 86%;
    }

    @media (max-width: 768px) {
      left: 87%;
    }

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
        rotate: 180deg;
      }
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-next {
    right: 7%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
        rotate: 180deg;
      }
    }

    @media (max-width: 778px) {
      right: 16% !important;
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  @media (max-width: 600px) {
    .slick-prev:before {
      position: relative;
      bottom: 60px;
    }

    .slick-next:before {
      position: relative;
      bottom: 60px;
    }
  }
}

// Blog Slider CSS Start
.blog-slider {
  .slick-prev,
  .slick-next {
    bottom: -20%;
    top: unset;
  }

  .slick-prev {
    left: -34.5%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }

    @media (max-width: 1024px) {
      left: -35%;
    }

    @media (max-width: 778px) {
      left: 78%;
    }

    @media (max-width: 475px) {
      left: 78%;
    }
  }

  .slick-next {
    left: -28%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (max-width: 1024px) {
    .slick-prev,
    .slick-next {
      bottom: 10%;
      rotate: 0deg;
    }

    .slick-next {
      left: -22%;
    }

    .slick-prev {
      left: -30%;
    }

    .popular-slider {
      .slick-list {
        .slick-track {
          display: flex !important;
          gap: 30px !important;
        }
      }
    }
  }

  @media (max-width: 778px) {
    .slick-next {
      right: 118%;

      &::before {
        height: 50px;
        width: 44px;
      }
    }
  }

  @media (max-width: 479px) {
    .slick-prev,
    .slick-next {
      bottom: 118%;
    }

    .slick-next {
      right: 8% !important;
      left: unset;
    }

    .slick-prev {
      left: 76%;
    }
  }
  @media (max-width: 320px) {
    .slick-prev,    
    .slick-next {
      right: 8% !important;
      left: unset;
    }

    .slick-prev {
      left:70%;
    }
  }
}

@media (max-width: 600px) {
  .popular-slider {
    .slick-prev,
    .slick-next {
      bottom: 109% !important;
    }

    .slick-next {
      right: 8% !important;
      left: unset;
    }

    .slick-prev {
      left: 75%;
    }
  }

  .popular-slide {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  .case-mobile-slider {
    .slick-prev,
    .slick-next {
      bottom: 109% !important;
      rotate: 180deg;
      right: 12% !important;
      display: none;
    }

    .slick-prev {
      // right: 100px !important;
      left: 72% !important;
    }
  }
  
}
@media (max-width: 320px) {
  .case-mobile-slider {
    .slick-prev,
    .slick-next {     
      right: 10.5% !important;
    }

    .slick-prev {      
      left: 67% !important;
    }
  }
}


// Blog Slider CSS END

.top-stories {
  .slick-prev,
  .slick-next {
    bottom: unset;
    top: -20%;
  }

  .slick-prev {
    left: 85%;

    @media (max-width: 1024px) {
      left: 85%;
    }

    @media (max-width: 768px) {
      left: 78%;
    }

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
        rotate: 180deg;
      }
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-next {
    right: 10%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }

    @media (max-width: 778px) {
      right: 10% !important;
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    display: flex;
  }

  .slick-track {
    display: flex;
    gap: 30px;
  }
}

.mobile-app-slider {
  .slick-track {
    display: flex;
    gap: 0px;
  }

  @media (max-width: 1024px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  @media (max-width: 778px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  @media (max-width: 479px) {
    .slick-track {
      gap: 0px;
    }
  }

  .slick-prev,
  .slick-next {
    bottom: -20%;
    top: unset;
  }

  .slick-prev {
    left: 83%;

    @media (max-width: 1024px) {
      left: 85%;
    }

    @media (max-width: 768px) {
      left: 78%;
    }

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
        rotate: 180deg;
      }
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-next {
    right: 10%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
        rotate: 180deg;
      }
    }

    @media (max-width: 778px) {
      right: 10% !important;
    }

    @media (max-width: 475px) {
      right: 30% !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    overflow: visible;
  }
}

.our-Premier {
  .slick-track {
    display: flex;
    gap: 40px;
  }

  .slick-prev,
  .slick-next {
    bottom: -20%;
    top: unset;
  }

  .slick-prev {
    left: 83%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }
  }

  .slick-next {
    right: 10%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }
    }

    @media (max-width: 778px) {
      right: 10% !important;
    }

    @media (max-width: 475px) {
      right: 15% !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    overflow: visible;
  }

  @media (max-width: 1366px) {
    .slick-track {
      display: flex;
      gap: 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }

    .slick-track {
      left: 0;
    }

    .slick-prev {
      left: 80%;
    }
  }

  @media (max-width: 820px) {
    .slick-track {
      display: flex;
      gap: 5px;
    }

    .slick-prev {
      left: 80%;
    }
  }

  @media (max-width: 778px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }

    .slick-track {
      left: 0px;
    }

    .slick-prev {
      left: 78%;
    }
  }

  @media (max-width: 479px) {
    .slick-track {
      gap: 0px;
      left: 0px;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-prev {
      left: 68%;
    }
  }
  @media (max-width: 320px) {
    .slick-prev {
      left: 60%;
    }
  }
}

.our-services {
  .slick-track {
    display: flex;
    gap: 40px;
    left: -40px;
  }

  .slick-list {
    overflow: visible;
  }

  @media (min-width: 1440px) {
    .slick-track {
      display: flex;
      gap: 40px;
      left: -100px;
    }
  }

  .slick-next,
  .slick-prev {
    transition: opacity 0.3s ease-in-out;
    bottom: -20%;
    top: unset;

    @media (max-width: 768px) {
      &::before {
        height: 30px;
        width: 30px;
      }
    }
  }

  .slick-prev {
    left: 95%;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5 !important;
    pointer-events: none;
    visibility: visible; // Ensure it doesn’t disappear
  }

  .slick-next:before,
  .slick-prev:before {
    opacity: 0;
  }

  // Lazy load the background when visible
  .slick-next::before {
    background-image: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg");
    opacity: 1;
    display: block;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 30px;
    height: 30px;
  }

  .slick-prev::before {
    background-image: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg");
    opacity: 1;
    display: block;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 30px;
    height: 30px;
  }

  // .slick-slide {
  //     margin-right: 15px;
  //   }

  @media (max-width: 600px) {
    .slick-next {
      right: 10px;
    }

    .slick-prev {
      left: 80%;
    }

    .slick-track {
      gap: 0px;
      left: 15px;
    }

    .slick-slide {
      margin-right: 15px;
    }

    .slick-track {
      .slick-slide:last-child {
        margin-right: 0px;
      }
    }
  }
  @media (max-width: 320px) {
    .our-services .slick-next {
      right: 10px;
  }
  .our-services .slick-prev {
    left: 70%;
}
  }
  // .slick-track {
  //   display: flex;
  //   gap: 40px;
  //   left: -100px;
  // }

  // .slick-prev,
  // .slick-next {
  //   bottom: -20%;
  //   top: unset;

  //   &.slick-disabled {
  //     opacity: 0.25;
  //   }
  // }

  // .slick-prev {
  //   left: 83%;
  //   &::before{
  //     content: "";
  //     background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
  //       no-repeat center;
  //     display: block;
  //     width: 56px;
  //     height: 64px;
  //   }
  //   &.lazy-loaded::before {
  //     content: "";
  //     background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
  //       no-repeat center;
  //     display: block;
  //     width: 56px;
  //     height: 64px;

  //     @media (max-width: 768px) {
  //       width: 44px;
  //       height: 50px;
  //     }
  //   }
  // }

  // .slick-next {
  //   right: 10%;
  //   &::before{
  //     content: "";
  //     background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
  //       no-repeat center;
  //     display: block;
  //     width: 56px;
  //     height: 64px;
  //   }
  //   &.lazy-loaded::before {
  //     content: "";
  //     background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
  //       no-repeat center;
  //     display: block;
  //     width: 56px;
  //     height: 64px;

  //     @media (max-width: 768px) {
  //       width: 44px;
  //       height: 50px;
  //     }
  //   }
  // }

  // .slick-prev:before,
  // .slick-next:before {
  //   font-family: "slick";
  //   font-size: 26px;
  //   opacity: 0.75;
  //   color: black;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }

  // .slick-list {
  //   overflow: visible;
  // }

  // @media (max-width: 1366px) {
  //   .slick-track {
  //     gap: 1.5rem;
  //   }
  // }

  // @media (max-width: 1024px) {
  //   .slick-slider {
  //     margin: 0 -7px;

  //     .slick-slide {
  //       padding: 0 7px;
  //     }
  //   }

  //   .slick-track {
  //     left: 0;
  //   }

  //   .slick-prev {
  //     left: 80%;
  //   }
  // }

  // @media (max-width: 820px) {
  //   .slick-track {
  //     gap: 5px;
  //   }

  //   .slick-prev {
  //     left: 80%;
  //   }
  // }

  // @media (max-width: 778px) {
  //   .slick-slider {
  //     margin: 0 -7px;

  //     .slick-slide {
  //       padding: 0 7px;
  //     }
  //   }

  //   .slick-track {
  //     left: 0;
  //   }

  //   .slick-prev {
  //     left: 78%;
  //   }
  // }

  // @media (max-width: 479px) {
  //   .slick-track {
  //     gap: 0;
  //     left: 0;
  //   }

  //   .slick-list {
  //     overflow: hidden;
  //   }

  //   .slick-prev {
  //     left: 68%;
  //   }
  // }
}

.flutterslider {
  .slick-track {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 479px) {
    .slick-track {
      display: flex;
      gap: 23px;
    }
  }
}

.related-slider {
  .slick-track {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 479px) {
    .slick-track {
      display: flex;
      gap: 0;
    }
  }
}

@media (max-width: 600px) {
  .mobile-padding {
    padding-top: 5rem !important;
  }
}

.backend-slider {
  .slick-track {
    display: flex;
    gap: 40px;
    left: -100px;
  }

  .slick-prev,
  .slick-next {
    bottom: -20%;
    top: unset;
  }

  .slick-prev {
    left: 83%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-left.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }

      @media (max-width: 600px) {
        height: 30px;
        width: 30px;
      }
    }
  }

  .slick-next {
    right: 10%;

    &.slick-disabled {
      opacity: 0.25;
    }

    &::before {
      content: "";
      background: url("https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Icon-ionic-ios-arrow-dropright-circle-right.svg")
        no-repeat;
      height: 64px;
      width: 56px;
      display: block;
      background-position: center;

      @media (max-width: 768px) {
        height: 50px;
        width: 44px;
      }

      @media (max-width: 600px) {
        height: 30px;
        width: 30px;
      }
    }

    @media (max-width: 778px) {
      right: 10% !important;
    }

    @media (max-width: 475px) {
      right: 15% !important;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 26px;
    line-height: 1;
    opacity: 0.75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    overflow: visible;
  }

  @media (max-width: 1366px) {
    .slick-track {
      display: flex;
      gap: 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }

    .slick-track {
      left: -50px;
    }

    .slick-prev {
      left: 80%;
    }
  }

  @media (max-width: 820px) {
    .slick-track {
      display: flex;
      gap: 5px;
    }

    .slick-prev {
      left: 80%;
    }
  }

  @media (max-width: 778px) {
    .slick-slider {
      margin-left: -7px;
      margin-right: -7px;

      .slick-slide {
        padding-left: 7px;
        padding-right: 7px;
      }
    }

    .slick-track {
      left: -30px;
    }

    .slick-prev {
      left: 78%;
    }
  }

  @media (max-width: 479px) {
    .slick-track {
      gap: 0px;
      left: 0px;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-prev {
      left: 68%;
    }
  }
}

.NewStoryIndustryslider {
  .slick-slider {
    .slick-prev:before {
      display: none;
    }

    .slick-next:before {
      display: none;
    }
  }

  @media (max-width: 820px) {
    .slick-track {
      gap: 1.5rem;
    }
  }
}

.PremierRetail {
  @media (max-width: 1024px) {
    .slick-prev {
      left: 82%;
    }
  }

  @media (max-width: 768px) {
    .slick-prev {
      left: 80%;
    }

    .slick-next {
      right: 10% !important;
    }
  }

  @media (max-width: 430px) {
    .slick-prev {
      left: 60%;
    }

    .slick-next {
      right: 10% !important;
    }
  }
}

.Explore-slider .slick-list {
  overflow: hidden;
}

.Explore-slider {
  .cardBorder {
    border: 1px solid #d8d8d800;
  }
}

@media (max-width: 1024px) {
  .Explore-slider .our-services .slick-next {
    right: 6% !important;
  }
}

@media (max-width: 820px) {
  .Explore-slider .our-services .slick-next {
    bottom: -33%;
  }

  .Explore-slider .our-services .slick-prev {
    bottom: -33%;
  }
}

@media (max-width: 430px) {
  .Explore-slider .our-services .slick-next {
    right: 15%;
  }

  .Explore-slider .our-services .slick-prev {
    left: 60%;
  }
}

.tabbtn {
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 12px;
  height: 50px;
  font-size: 17px;
  font-weight: bold;
  background: #f8f8f8;

  &.active {
    background: #d34d7b;
    color: #ffffff;
  }
}

.RL {
  div {
    text-decoration: unset;
  }

  div:nth-child(1) {
    margin-top: -5rem;
  }

  div:nth-child(2) {
    margin-top: 12rem;
  }

  div:nth-child(3) {
    margin-top: -10rem;
  }

  div:nth-child(4) {
    margin-top: 5rem;
  }

  div:nth-child(5) {
    margin-top: -4rem;
  }

  div:nth-child(6) {
    margin-top: 5rem;
  }

  div:nth-child(8) {
    margin-top: 5rem;
  }

  @media (max-width: 1024px) {
    div:nth-child(2) {
      margin-top: 4rem;
    }

    div:nth-child(3) {
      margin-top: 4rem;
    }

    div:nth-child(5) {
      margin-top: 5rem;
    }

    div:nth-child(7) {
      margin-top: 7rem;
    }
  }

  @media (max-width: 778px) {
    div:nth-child(2) {
      margin-top: 4rem;
    }

    div:nth-child(3) {
      margin-top: 4rem;
    }

    div:nth-child(4) {
      margin-top: 4rem;
    }

    div:nth-child(5) {
      margin-top: 4rem;
    }

    div:nth-child(6) {
      margin-top: 4rem;
    }

    div:nth-child(7) {
      margin-top: 4rem;
    }

    div:nth-child(8) {
      margin-top: 4rem;
    }
  }
}

.Recommended {
  @media (max-width: 820px) {
    transform: scaleX(-1);
    left: -21px;
  }
}

.tx-center {
  @media (max-width: 600px) {
    text-align: center;
  }
}

.screen {
  @media (max-width: 1180px) {
    right: 66% !important;
  }

  @media (max-width: 820px) {
    right: 57% !important;
  }

  @media (max-width: 430px) {
    right: 21% !important;
  }
}

.hours {
  @media (max-width: 1024px) {
    right: -13px !important;
  }

  @media (max-width: 820px) {
    left: 173px;
    bottom: 100px;
  }

  @media (max-width: 430px) {
    bottom: 124px;
  }
}

.start {
  @media (max-width: 820px) {
    top: -55px;
    left: 72px;
  }
}

.Challenges {
  @media (max-width: 1180px) {
    top: 29% !important;
    right: 161px !important;
  }

  @media (max-width: 820px) {
    top: 40% !important;
    right: 44% !important;
  }

  @media (max-width: 600px) {
    top: 24% !important;
    right: 44% !important;
  }

  @media (max-width: 430px) {
    right: -9% !important;
  }
}

.min-h-screen-header {
  min-height: calc(100vh - 100px);
}

#hubspot-form-wrapper
  #hs-form-iframe-0
  #hbspt-form-81320b5b-7aa2-4dde-9881-37bf347e49f6
  #hsForm_9e8287b7-9da0-4b04-82be-8b458ad0ecbe {
  .legal-consent-container {
    display: none;
  }
}

#hbspt-form-950ea15e-c213-4820-b0c6-5c76db6486b1 {
  .legal-consent-container {
    display: none;
  }
}

.legal-consent-container {
  display: none;
}

.hook-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background-color: transparent;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hook-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.RFP-form .hook-input:focus {
  border-color: #ccc;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.hook-input::placeholder {
  color: #6c757d;
  opacity: 1;
}

.hook-input:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.hook-input.text-danger {
  border-color: #dc3545;
}

.hook-input.error-danger:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.application_Slider {
  &.slick-slider {
    margin-left: -10px;
    margin-right: -10px;

    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-prev,
  .slick-next {
    font-size: 18px;
    height: 50px;
    width: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
    bottom: -40px;
    top: unset;
    right: 0;
    left: 0;
    margin: auto;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .slick-disabled {
    opacity: 0.5;
    color: #ccc1b6;
  }

  .slick-track {
    margin-left: 0;
  }

  @media (min-width: 1100px) and (max-width: 1220px) {
    .slick-next {
      right: -34px;
    }

    .slick-prev {
      left: -30px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev,
    .slick-next {
      bottom: -40px;
      top: unset;
    }
  }

  @media (max-width: 820px) {
    .slick-prev,
    .slick-next {
      font-size: 16px;
      height: 47px;
      width: 47px;
    }
  }
}

.business_slider {
  &.slick-slider {
    margin-left: -10px;
    margin-right: -10px;

    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-prev,
  .slick-next {
    font-size: 18px;
    height: 50px;
    width: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
    bottom: -40px;
    top: unset;
    right: 0;
    left: 0;
    margin: auto;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .slick-disabled {
    opacity: 0.5;
    color: #ccc1b6;
  }

  .slick-track {
    margin-left: 0;
  }

  @media (min-width: 1100px) and (max-width: 1220px) {
    .slick-next {
      right: -34px;
    }

    .slick-prev {
      left: -30px;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev,
    .slick-next {
      bottom: -40px;
      top: unset;
    }
  }

  @media (max-width: 820px) {
    .slick-prev,
    .slick-next {
      font-size: 16px;
      height: 47px;
      width: 47px;
    }
  }
}

.optcard {
  min-height: 300px;
}

.contactSliderMain {
  @media (max-width: 1024px) {
    height: unset;
  }
}

.iconBar_slider {
  svg {
    height: 50px;
    width: 50px;
  }
}

.copyright {
  width: 100%;
  width: 100%;
  display: grid;
  justify-items: stretch;
  justify-content: space-between;
  grid-template-columns: 1fr 350px;
  gap: 2.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr !important;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
  }
}

.terms {
  @media (max-width: 991px) {
    flex-direction: column !important;
    align-items: flex-start !important;

    .cright {
      order: 2 !important;
    }

    .fprivacy {
      order: 1 !important;
    }
  }

  @media (max-width: 475px) {
    align-items: center !important;
  }
}

.stylebase-nav {
  ::-webkit-scrollbar {
    width: 20px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9f9f9f;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #919191;
  }
}

.career-form-input {
  padding: 1rem 0;

  input {
    border-radius: 0;
    height: 30px;
    width: 30px;
  }

  &.hook-radio:checked {
    background-color: #000 !important;
    border-color: #000 !important;
  }
}

@media (max-width: 768px) {
  .LetsTalkSecTitle {
    font-size: 26px !important;
    line-height: 36px !important;
  }
}

@media (max-width: 600px) {
  .LetsTalkSecTitle {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center;
  }
}

.cls-main {
  .cls-logo {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    max-height: 124px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
}

.h-btn {
  max-width: max-content;
  display: flex !important;
  align-items: center;
  margin-top: 3rem;
  border-radius: 50px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  svg.iconify.iconify--solar {
    margin-inline-start: 0.75rem;
    margin-left: 1rem !important;
  }
}

.rfp-btn {
  background-color: #0e1a3a;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 26px;
  border: none;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(90deg, #dd2f63, #ec529f);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.certificate {
  .rfm-child svg {
    width: 65%;
    margin-left: 0.5rem;
    display: flex;
  }
}

@media (max-width: 375px) {
  .ArianeMain {
    margin-top: 15rem;
  }
}

.HomeTwoImgSliderRowFilmstrips {
  margin-bottom: 136px;
  margin-top: 136px;
  overflow: hidden;

  .HomeTwoImgfilmstripRow {
    display: grid;
    column-gap: 55px;
    grid-template-columns: 854px 854px 854px;
    position: relative;
    transition: transform 0.3s ease-out;
  }

  .HomeTwoImgfilmstripRow.HomeTwoImgAnimateRight {
    margin-left: -600px;
  }

  .HomeTwoImgfilmstripRow.HomeTwoImgAnimateLeft {
    margin-left: -227px;
  }

  .HomeTwoImgWrap {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
  }

  .HomeTwoImgWrap img {
    object-fit: cover;
  }

  @media (max-width: 1699px) {
    .HomeTwoImgfilmstripRow {
      column-gap: 3vw;
      grid-template-columns: 51vw 51vw 51vw;
      margin-left: -30vw;
    }
  }

  @media (max-width: 1199px) {
    .HomeTwoImgfilmstripRow {
      column-gap: 3vw;
      grid-template-columns: 55vw 55vw 55vw;
      margin-left: -35vw;
    }
  }

  @media (max-width: 979px) {
    .HomeTwoImgfilmstripRow {
      margin-bottom: 3vw;
    }
  }

  @media (max-width: 767px) {
    .HomeTwoImgfilmstripRow {
      column-gap: 5vw;
      grid-template-columns: 81vw 81vw 81vw;
      margin-left: -32vw;
    }
  }
}

.innerWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.imgWrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgWrapMobile {
  display: none;
}

@media (max-width: 480px) {
  .imgWrap {
    display: none;
  }

  .imgWrapMobile {
    display: block;
  }
}

.videoSection {
  position: relative;
  z-index: 1;
}

.videoRow {
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: -1;
}

.desktopImage,
.mobileImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobileImage {
  display: none;
}

.playButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 150px;
  height: 150px;
  background: #fb2979;
  z-index: 0;
  border: none;
  border-radius: 50%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.playButton:hover {
  transform: scale(0.95);
}

.playButton svg {
  width: 47px;
  height: 47px;
  fill: white;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.videoContainer.active {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1500px) {
  .playButton {
    width: 10vw;
    height: 10vw;
  }

  .playButton svg {
    width: 3.2vw;
    height: 3.2vw;
  }
}

@media (max-width: 480px) {
  .videoRow {
    border-radius: 10px;
  }

  .videoWrapper {
    display: none;
  }

  .mobileImage {
    display: flex;
  }

  .playButton {
    width: 12.5vw;
    height: 12.5vw;
  }

  .playButton svg {
    width: 3.5vw;
    height: 3.5vw;
  }
}

.MySquardImgScroll {
  .row__casestudy--image {
    margin-top: 68px;
    padding: 0 60px;
  }

  .image__container {
    width: 100%;
    height: 796px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .bg__image {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .main__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1268px;
    height: 642px;
  }

  .main__image.to_parallax_scroll {
    transition: transform 0.2s ease-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media all and (max-width: 1500px) {
    .image__container {
      height: 54vw;
    }

    .row__casestudy--image {
      padding: 0 30px;
    }

    .main__image {
      width: 73vw;
      height: 37vw;
    }
  }

  @media all and (max-width: 979px) {
    .image__container {
      height: 54vw;
    }

    .main__image {
      width: 80vw;
      height: 40vw;
    }
  }

  @media all and (max-width: 480px) {
    .image__container {
      height: 50vw;
    }

    .main__image {
      width: 73vw;
      height: 37vw;
    }
  }
}
