/* boostrap css start */
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.overflow-visible {
  overflow: visible !important;
}

.form-label {
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

iframe {
  border: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

textarea {
  resize: vertical;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
  display: flex;
  align-items: center;
}

img,
svg {
  vertical-align: middle;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 1rem;
  margin: 0.5rem;

  @media (max-width: 600px) {
    margin: 0rem;

    &.modal.show {
      opacity: 1;
      display: grid !important;
    }
  }
}

.modal-backdrop {
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
}

.fade {
  transition: opacity 0.15s linear;
}

.modal.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.modal.show {
  opacity: 1;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;

  video {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal.show .modal-dialog {
  transform: none;
  margin: 0 auto;
}

//
// Base styles
//

.accordion {
  // scss-docs-start accordion-css-vars

  --accordion-btn-icon: url("../public/svgs/pluse.svg");
  --accordion-btn-icon-width: 15px;
  //--accordion-btn-icon-transform: #{$accordion-icon-transform};
  //--accordion-btn-icon-transition: #{$accordion-icon-transition};
  --accordion-btn-active-icon: url("../public/svgs/minus.svg");
  // scss-docs-end accordion-css-vars
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 5px;
  color: #000;
  text-align: left; // Reset button style
  background-color: #f1f1f1;
  border: 0;
  overflow-anchor: none;

  &:not(.collapsed) {
    color: red;
    background-color: blue;
    box-shadow: inset 0 calc(-1 * 5px) 0 yellow; // stylelint-disable-line function-disallowed-list

    &::after {
      background-image: var(--accordion-btn-active-icon);
      transform: var(--accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--accordion-btn-icon-width);
    height: var(--accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--accordion-btn-icon-width);
    //@include transition(var(--accordion-btn-icon-transition));
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--accordion-btn-focus-box-shadow);
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--accordion-color);
  background-color: var(--accordion-bg);
  border: var(--accordion-border-width) solid var(--accordion-border-color);

  &:first-of-type {
    //@include border-top-radius(var(--accordion-border-radius));

    >.accordion-header .accordion-button {
      //@include border-top-radius(var(--accordion-inner-border-radius));
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    //@include border-bottom-radius(var(--accordion-border-radius));

    >.accordion-header .accordion-button {
      &.collapsed {
        //@include border-bottom-radius(var(--accordion-inner-border-radius));
      }
    }

    >.accordion-collapse {
      //@include border-bottom-radius(var(--accordion-border-radius));
    }
  }
}

.accordion-body {
  padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  >.accordion-item {
    border-right: 0;
    border-left: 0;
    //@include border-radius(0);

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    // stylelint-disable selector-max-class
    >.accordion-header .accordion-button {

      &,
      &.collapsed {
        // @include border-radius(0);
      }
    }

    // stylelint-enable selector-max-class

    >.accordion-collapse {
      // @include border-radius(0);
    }
  }
}

// @if $enable-dark-mode {
//   @include color-mode(dark) {
//     .accordion-button::after {
//       --accordion-btn-icon: #{escape-svg($accordion-button-icon-dark)};
//       --accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon-dark)};
//     }
//   }
// }

// Variables
$primary-color: #007bff;
$border-color: #ddd;
$transition-speed: 0.3s;

// Accordion Styles
.accordion {
  //border: 1px solid $border-color;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: auto;
}

.accordion-item {
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }
}

.accordion-header {
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  transition: color $transition-speed;
  opacity: 1;
  letter-spacing: -1px;
  margin-bottom: 32px;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}

.accordion-icon {
  width: 20px; // Customize your icon size
  height: 20px;
  display: contents;

  img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease-in-out;
  }
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height $transition-speed ease-in-out;
  padding: 0 15px;

  &.show {
    max-height: 200px;
    padding: 15px;
  }
}

/* boostrap css end */

h2 {
  opacity: 1;
  letter-spacing: -1px;
  margin-bottom: 32px;
  font-size: 46px;
  font-weight: 700;
  line-height: 60px;
}

p {
  padding: 0px;
  margin: 0px;
  font-weight: 300;
}

.h2_cards-title {
  text-align: center;
  max-width: 596px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.headerSub-text-3 {
  max-width: 670px;
  margin-top: 18px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  text-align: center;

  p {
    letter-spacing: -0.2px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 32px;
    display: block;
  }
}

.h3-card_title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 38px;
  display: block;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.p-b-section {
  padding-bottom: 160px !important;
}

.p-t-section {
  padding-top: 160px;
}

.box-title {
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 30px;
  display: inline;
}

.box-text {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 32px;
}

.sub-sec-title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 38px;
  display: block;
}

@media screen and (min-width: 1920px) {
  .box-text {
    letter-spacing: normal;
  }
}

@media screen and (min-width: 1440px) {
  .box-text {
    letter-spacing: 0.5px;
    font-size: 22px;
    line-height: 38px;
  }

  .ExpertiseBoxText {
    letter-spacing: 0.5px;
    font-size: 18px;
    line-height: 28px;
  }

  .sub-sec-title {
    letter-spacing: -1px;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 46px;
  }

  .box-title {
    letter-spacing: -0.6px;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 48px;
  }

  .ExpertiseBoxTitle {
    letter-spacing: -0.6px;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
  }

  .p-b-section {
    padding-bottom: 180px !important;
  }

  .p-t-section {
    padding-top: 180px;
  }

  .h2_cards-title {
    max-width: 800px;
    margin-bottom: 80px;
  }

  h2 {
    letter-spacing: -1.5px;
    margin-bottom: 40px;
    margin-top: 0;
    font-size: 58px;
    line-height: 76px;
    font-weight: 700;
  }

  .headerSub-text-3 {
    max-width: 760px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;

    p {
      letter-spacing: -0.2px;
      margin-bottom: 40px;
      font-size: 26px;
      line-height: 42px;
    }
  }

  .h3_title {
    letter-spacing: -1px;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 46px;
  }
}

@media screen and (max-width: 1400px) {
  .ExpertiseBoxText {
    letter-spacing: 0.5px;
    font-size: 18px;
    line-height: 24px;
  }

  .ExpertiseBoxTitle {
    letter-spacing: 0px;
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 991px) {
  .box-text {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
  }

  .ExpertiseBoxText {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
  }

  .sub-sec-title {
    font-size: 22px;
    line-height: 32px;
  }

  .box-title {
    letter-spacing: -0.5px;
    font-size: 22px;
    line-height: 32px;
  }

  .ExpertiseBoxTitle {
    letter-spacing: -0.5px;
    font-size: 15px;
    line-height: 25px;
  }

  .p-b-section {
    padding-bottom: 140px !important;
  }

  .p-t-section {
    padding-top: 140px;
  }

  h2 {
    font-size: 34px;
    line-height: 46px;
    opacity: 1;
    letter-spacing: -1px;
    margin-bottom: 32px;
  }

  .h2_cards-title {
    max-width: 510px;
    margin-bottom: 48px;
  }

  .headerSub-text-3 {
    max-width: 700px;
    margin-top: 18px;
    margin-left: auto;
    padding-left: 0;
    margin-right: auto;

    p {
      letter-spacing: -0.2px;
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .h3_title {
    font-size: 22px;
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .box-text {
    letter-spacing: 0;
  }

  .sub-sec-title {
    letter-spacing: -0.5px;
  }
}

@media screen and (max-width: 778px) {
  h2 {
    letter-spacing: -1px;
    width: 100%;
    margin-bottom: 24px;
    font-weight: 700;
  }

  .h2_cards-title {
    max-width: 446px;
  }

  .headerSub-text-3 {
    margin-top: 0;

    p {
      letter-spacing: 0.1px;
      margin-bottom: 16px;
    }
  }

  .h3_title {
    letter-spacing: -0.5px;
  }
}

@media screen and (max-width: 479px) {
  .p-t-section-m {
    padding-top: 100px;
  }

  .box-text {
    font-size: 14px;
    line-height: 22px;
  }

  .ExpertiseBoxText {
    font-size: 14px;
    line-height: 24px;
  }

  .sub-sec-title {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;
  }

  .box-title {
    letter-spacing: 0.2px;
    font-size: 18px;
    line-height: 28px;

    br {
      display: none;
    }
  }

  .ExpertiseBoxTitle {
    font-size: 18px;
    line-height: 28px;
  }

  .p-b-section {
    padding-bottom: 100px !important;
  }

  .p-t-section {
    padding-top: 100px;
  }

  h2 {
    letter-spacing: -0.5px;
    font-size: 26px;
    line-height: 36px;
  }

  .h2_cards-title {
    max-width: 340px;
    margin-bottom: 30px;
  }

  .headerSub-text-3 {
    max-width: 400px;

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .h3_title {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 390px) and (max-width: 415px) {
  .ExpertiseBoxTitle {
    font-size: 16px;
    line-height: 26px;
    width: 100% !important;
    gap: 0;

    span {
      min-width: fit-content;
    }
  }
}

@media screen and (max-width: 360px) {
  .SaaSMenuGap {
    gap: 6.3px;
  }

  .SaaSMenuFont {
    font-size: 6.5px;
  }

  .ExpertiseBoxTitle {
    font-size: 16px;
    line-height: 26px;
  }
}

.NewStoryIndustryslider {
  padding: 1rem;
}

.scroll-item {
  flex: 0 0 auto;
  width: 300px;
  height: 350px;
  background: var(--background-color);
  border-radius: 25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
}

.scroll-item:hover {
  transform: scale(1.05);
}

.HomeTwoMarqueeLogo {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #1c1d1f;

  .rfm-overlay {
    --gradient-color: none !important;
  }
}

@media screen and (min-width: 1440px) {
  .HomeTwoDiscoveryImageGrid {
    width: calc(25% - 65px);
    height: 530px;
  }

  .SeoServicesTwoImgSectionOne {
    width: calc(60% - 30px);
  }

  .SeoServicesTwoImgSectionTwo {
    width: 65.5%;
  }
}

@media screen and (max-width: 1700px) {
  .HomeTwoDiscoveryImageGrid {
    width: calc(25% - 3vw);
    height: 31.5vw;
  }
}

@media screen and (max-width: 1366px) {
  .SeoServicesTwoImgSectionOne {
    width: calc(67% - 30px);
  }
}

@media screen and (max-width: 1199px) {
  .HomeTwoDiscoveryImageGrid {
    width: calc(25% - 2vw);
    height: 31.5vw;
  }
}

@media screen and (max-width: 979px) {
  .SeoServicesTwoImgSectionOne {
    width: calc(50% - 10px);
  }

  .SeoServicesTwoImgSectionTwo {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .HomeTwoDiscoveryImageGrid {
    width: calc(25% + 7vw);
    height: 46vw;
  }

  .SeoServicesTwoImgSectionOne {
    width: calc(50% - 5px);
  }

  .SeoServicesTwoImgSectionTwo {
    width: calc(50% - 5px);
  }
}

.font1 {
  font-family: "GTWalsheimPro", Tahoma, Verdana, sans-serif;
}

.font2 {
  font-family: "GTWalsheimPro", Tahoma, Verdana, sans-serif;
}

@media screen and (max-width: 991px) {
  .MobileApp-Singapore-Leverage-Cards {
    flex-wrap: wrap;
  }
}